import axios from "axios";

export const registeredUsers = {
    namespaced: true,
    state: {
        data:[],
        dialog:false,
        title:"",
        total:0,
        connectionError:null,
    },
    mutations: {
        setData(state,val){
            state.data = val;
        },
        setTitle(state,val){
            state.title = val;
        },
        setTotal(state,val){
            state.total = val;
        },
        setError(state,val){
            state.connectionError = val;
        },
        ShowDialog(state,val){
            state.dialog = val
        }
    },
    actions: {
        GetData({commit,dispatch},row) {
            commit("setData", [])
            dispatch("loading", true, {root: true})

            let url = "https://app.moowifi.com.tr/includes/UserList.php?list=registered&id=" + row.P_Id + "&token=VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk="

            axios.get(url)
                .then((res) => {
                    commit("setError", null);
                    commit("setData", res.data);
                    commit("setTitle", row.P_Header);
                    commit("setTotal", res.data.length);
                    commit("ShowDialog", true);
                    dispatch("loading", false, {root: true})
                }).catch((e) => {
                console.log(e);
                dispatch("loading", false, {root: true})
            })
        }
    },
}