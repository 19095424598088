import Vue from 'vue'
import Vuex from 'vuex'
import {hotspot} from "./hotspot"
import {business} from "./business"
import {users} from "./users"
import {inhouse} from "./inhouse"
import {registeredUsers} from "./registered-user"
import {refreshplace} from "./refreshplace";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer:null,
    PageTitle:'Dashboard',
    search:'',
    isAuthenticated:false,
    isAdmin:false,
    loading:false
  },
  mutations: {
    setAuth(state,val){
      state.isAuthenticated = val
    },
    setAdmin(state,val){
      state.isAdmin = val
    },
    setDrawer(state,val){
      state.drawer = val;
    },
    setPageTitle(state,val){
      state.PageTitle = val;
    },
    setSearch(state,val){
      state.search = val;
    },
    showLoading(state,val){
      state.loading = val
    }
  },
  actions: {
    loading({commit},value){
      commit("showLoading",value)
    }
  },
  modules: {
    hotspot,
    business,
    users,
    inhouse,
    registeredUsers,
    refreshplace
  }
})
