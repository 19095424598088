import axios from "axios";

export const business = {
    state: {
        data:[],
        last20:[],
        TotalHotSpotUser:0,
        TotalAdmin:0
    },
    mutations: {
        setBusinessData(state,val){
            state.data = val;
        },
        setLast20(state,val){
            state.last20 = val;
        },
        setTotalHotSpotUser(state,val){
            state.TotalHotSpotUser = val;
        },
        setTotalAdmin(state,val){
            state.TotalAdmin = val;
        },
    },
    actions: {
        BusinessListUpdate({dispatch}){
            dispatch("loading",true)
            dispatch("BusinessListAPI")
            setInterval(() => {
                dispatch("BusinessListAPI")
            }, 85000)
        },
        BusinessListAPI({commit,dispatch}){
            var url = "https://app.moowifi.com.tr/includes/PlaceInfo.php?list=now&token=VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk="
            axios.get(url)
                .then((res) => {
                    commit("setBusinessData",res.data)
                    dispatch("loading",false)
                }).catch((e) => {
                console.log(e);
            })
        },
        Last20List({commit,dispatch}){
            var url = "https://app.moowifi.com.tr/includes/PlaceInfo.php?last20list=now&token=VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk="
            axios.get(url)
                .then((res) => {
                    commit("setLast20",res.data)
                    dispatch("loading",false)
                }).catch((e) => {
                console.log(e);
            })
        },
        DashboardData({commit,dispatch}){
            var url = "https://app.moowifi.com.tr/includes/PlaceInfo.php?dashboard=now&token=VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk="
            axios.get(url)
                .then((res) => {
                    commit("setTotalHotSpotUser",res.data.TotalHotSpotUser)
                    commit("setTotalAdmin",res.data.TotalAdmin)
                    dispatch("loading",false)
                }).catch((e) => {
                console.log(e);
            })
        }
    },
}