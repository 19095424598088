import axios from "axios";

export const refreshplace = {
    namespaced: true,
    state: {
        data:[],
        dialog:false,
        title:"",
        total:0,
        connectionError:null,
        ErrorMessage:null,
    },
    mutations: {
        setData(state,val){
            state.data = val;
        },
        setTitle(state,val){
            state.title = val;
        },
        setTotal(state,val){
            state.total = val;
        },
        setError(state,val){
            state.connectionError = val;
        },
        setErrorMessage(state,val){
            state.ErrorMessage = val;
        },
        ShowDialog(state,val){
            state.dialog = val
        }
    },
    actions: {
        GetData({commit,dispatch},row){
            commit("setData",[])
            dispatch("loading",true,{root:true})
            let url="https://windows.moowifi.com.tr/includes/tracert.php?id=" + row.PlaceID + "&p=" + btoa(unescape(encodeURIComponent(row.P_Header))) + "&token=VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk=";
            axios.get(url)
                .then((res) => {

                    if(res.data.error){
                        let connectionError = `
                        <b>IP Port :</b> ${res.data.ip},${res.data.port} <br /> 
                        <b>Database:</b> ${res.data.database}<br />
                        <b>Username:</b> ${res.data.username}<br />
                        <b>Password:</b> ${res.data.password}<br />`
                        commit("setError", connectionError)
                        commit("setErrorMessage", res.data.message)

                    }else{

                        if(res.data.status=="success") {
                            commit("setData",res.data.people);
                            commit("setTotal", res.data.people.length);
                        }else{
                            commit("setData",res.data.message);
                            commit("setTotal", 0);
                        }
                        commit("setError", null);
                        commit("setTitle", row.P_Header);

                    }
                    commit("setTitle", row.P_Header)
                    commit("ShowDialog", true);
                    dispatch("loading",false,{root:true})
                }).catch((e) => {
                console.log(e);
                dispatch("loading",false,{root:true})
            })


        },
    },
}