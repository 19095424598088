import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import tr from 'vuetify/lib/locale/tr';


Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
      options: {
        customProperties: true,
        themeCache: {
          get: key => localStorage.getItem(key),
          set: (key, value) => localStorage.setItem(key, value),
        },
      },
    themes: {
      light: {
        primary: '#1976d2',
        background: '#1976d2',
        leftbar: '#eaecee',
        color: '#ffffff',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        color: '#ffffff',
        primary: '#363636',
        leftbar: '#363636',
        background: '#363636',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    },
  },
    lang: {
      locales: { tr },
      current: 'tr',
    },
});
