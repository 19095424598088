import axios from "axios";
export const hotspot = {
    state: {
        data:[],
        createDialog:false,
        NewUsers:null
    },
    mutations: {
        setHotSpotList(state,val){
            state.data = val;
        },
        AddHotSpot(state,val){
          state.data.push(val)
        },
        ShowCreateHotSpotDialog(state,val){
            state.createDialog = val
        },
        setNewUserList(state,val){
            state.NewUsers = val
        }
    },
    actions: {
        HotSpotListUpdate({dispatch}){
            dispatch("loading",true)
            dispatch("HotSpotListAPI")
            setInterval(() => {
                dispatch("HotSpotListAPI")
            }, 45000)
        },
        async HotSpotListAPI({commit,dispatch}){
            var url = "https://app.moowifi.com.tr/includes/PlaceList.php?token=VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk="
            axios.get(url)
                .then((res) => {
                    commit("setHotSpotList",res.data)
                    dispatch("loading",false)
                }).catch((e) => {
                dispatch("loading",false)
                console.log(e);
            })

        },
        async PlaceUpdateSoftware({commit,dispatch},row){
            dispatch("loading",true,{root:true})
            let url="https://windows.moowifi.com.tr/includes/tracert.php?id=" + row.PlaceID + "&p=" + btoa(unescape(encodeURIComponent(row.P_Header))) + "&token=VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk=";
            axios.get(url)
                .then((res) => {
                    commit("setNewUserList",res.data)
                    commit("ShowDialog", true);
                    dispatch("loading",false,{root:true})
                }).catch((e) => {
                dispatch("loading",false)
                console.log(e);
            })

        },
        HotSpotCreate({commit},place_detail){
            axios.post('includes/PlaceConfig.php?create=place', { place_detail })
                .then((res) => {
                    if(res.data.error){
                        return res.data.error
                    }
                    commit("AddHotSpot",res.data)
                    return res.data.success
                }).catch((e)=> {
                console.log(e);
            });
        }
    },
}