import axios from "axios";

export const users = {
    state: {
        data:[]
    },
    mutations: {
        setUsersData(state,val){
            console.log(val)
            state.data = val;
        },
        AddNewUser(state,val){
            state.data.push(val)
        }
    },
    actions: {
        UserListUpdate({dispatch}){
            dispatch("UsersListAPI")
            setInterval(() => {
                dispatch("UsersListAPI")
            }, 85000)
        },
        UsersListAPI({commit}){
            var url = "https://app.moowifi.com.tr/includes/UserList.php?list=all"
            axios.get(url)
                .then((res) => {
                    commit("setUsersData",res.data)
                }).catch((e) => {
                console.log(e);
            })
        },
        UsersRemoveAPI(user_detail){
            axios.post('https://app.moowifi.com.tr/includes/UserList.php?remove=user', { user_detail })
                .then((res) => {
                    console.log(res.data);
                }).catch((e)=>{
                console.log(e);
            })
        },
        UsersUpdateAPI(user_detail){
            axios.post('https://app.moowifi.com.tr/includes/UserList.php?update=user', { user_detail })
                .then((res) => {
                    console.log(res.data)
                    if(res.data.error){
                        return false
                    }
                }).catch((e)=>{
                console.log(e);
            })
        },
        CreateUserAPI({commit},user_detail){
            axios.post('includes/UserList.php?create=user', { user_detail })
                .then((res) => {
                    if(res.data.error){
                        return res.data.error
                    }
                    commit.AddNewUser(res.data);
                    return true;
                }).catch((e)=>{
                console.log(e);
            })
        }
    },
}