import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'
Vue.use(VueRouter)
const routes = [
  {
    path: "/login",
    name: "Login",
    meta: {
      requiresAuth: false,
      isAdmin:false,
    },
    component: () => import('../pages/Logins/index.vue')
  },
  {
    path: "/",
    component: () => import('../layouts/main.vue'),
    children: [{
      path: "dashboard",
      name: "Dashboard",
      meta: {
        requiresAuth: true,
        isAdmin:true,
      },
      component: () => import('../pages/Dashboard/index.vue')
    },
    {
      path: "business",
      name: "business",
      meta: {
        requiresAuth: true,
        isAdmin:true,
      },
      component: () => import('../pages/Business/index.vue')
    },
    {
      path: "users",
      name: "users",
      meta: {
        requiresAuth: true,
        isAdmin:true,
      },
      component: () => import('../pages/Users/index.vue')
    },
    {
      path: "hotspot",
      name: "hotspot",
      meta: {
        requiresAuth: true,
        isAdmin:true,
      },
      component: () => import('../pages/Hotspot/index.vue')
    },
    {
      path: "hotspot-users",
      name: "hotspotusers",
      meta: {
        requiresAuth: true,
        isAdmin:true,
      },
      component: () => import('../pages/HotspotUsers/index.vue')
    }]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  let isAuthenticated = sessionStorage.getItem("isAuthenticated") || false;
  let isAdmin = sessionStorage.getItem("isAdmin") || false;
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({name: 'Login'})
  }else{
    if(to.meta.isAdmin && !isAdmin){
      alert("Bu işlemi gerçekleştirmek için yetkiniz yoktur.")
    }else{
      next()
    }

  }
})
export default router
